import React from 'react'
import { useDispatch } from 'react-redux';
import { LoginModalActions } from '../../../_actions/loginModal.actions';
import { useTranslation } from 'react-i18next';

export default function LoginErrorModal() {
    const { t } = useTranslation("common")
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(LoginModalActions.closeModal())
    }

    return (
        <div className="confirmation-modal login">
            <div className="confirmation-modal-header">{t("modals.loginError.header")}</div>
            <div className="confirmation-message">
                {t("modals.loginError.message")}
            </div>
            <div className="modal-footer">
                <div className="confirmation-modal-buttons">
                    <button onClick={handleClose} className="btn btn-primary w-100">{t("modals.close")}</button>
                </div>
            </div>
        </div>
    )
}