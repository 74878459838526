import React, { useState, useEffect, MouseEvent } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Modal } from 'react-bootstrap';
import { stripeService, userService } from '../../../_services';
import { accountService } from '../../../_services/accounts.service';
import { accountActions } from '../../../_actions/account.actions';
import { Account, State, User } from '../../../_types';
import { AppDispatch } from '../../../_store';
import { formatPrice } from '../../../_helpers/utility';
import { useTranslation } from 'react-i18next';

type PricingModalProps = {
    showModal: boolean;
    handleClose: () => void;
}

export default function PricingModal(props: PricingModalProps) {
    const { t } = useTranslation("common");
    const { showModal, handleClose } = props;
    const [numberOfUsers, setNumberOfUsers] = useState<number>(1);
    const [isYearly, setIsYearly] = useState(false);
    const [isContactForm, setIsContactForm] = useState(false);
    const [account, setAccount] = useState<Account>();
    const dispatch = useDispatch<AppDispatch>();

    const planPrices = {
        free: { monthly: 0, yearly: 0 },
        standard: { monthly: 50, yearly: 50 * 12 * 0.9 }
    };

    useEffect(() => {
        if (showModal) {
            setIsContactForm(false);
        }
    }, [showModal]);

    useEffect(() => {
        accountService.getAccount(undefined, ['servicePlan']).then(response => {
            const accountData = response.items[0];
            if (accountData) {
                setAccount(accountData);
            }
        });
    }, [])

    function portalClick(e: MouseEvent<HTMLDivElement>, planType: 'free' | 'standard') {
        if (account?.newAccount) {
            const accountUpdate = { id: account.id, newAccount: false };
            dispatch(accountActions.updateAccount(accountUpdate));
            if (planType === 'free') {
                handleClose();
                return;
            }
        }
        portalUrl(planType).then(url => window.open(url, '_blank'));
    }
    async function portalUrl(planType: 'free' | 'standard') {
        if (account?.newAccount && planType === 'free') {
            return;
        }
        const portal = await stripeService.getPortal();
        return portal.url;
    }

    function calculatePrice(planType: 'free' | 'standard') {
        if (planType in planPrices) {
            const price = isYearly ? planPrices[planType].yearly : planPrices[planType].monthly;
            const totalPrice = price * numberOfUsers;
            return `${formatPrice(totalPrice, account?.servicePlan?.currency || "USD")}`;
        }
        return '0';
    }

    function calculateSubtitlePrice(planType: 'free' | 'standard') {
        if (planType in planPrices && isYearly) {
            const yearlyEquivalentPerUser = planPrices[planType].yearly;
            const monthlyEquivalentForUsers = yearlyEquivalentPerUser * numberOfUsers;
            return `${formatPrice(monthlyEquivalentForUsers, account?.servicePlan?.currency || "USD")} ${t("pricing-modal.per-userYear")}`;
        }
        return `${formatPrice(planPrices[planType]?.monthly * numberOfUsers, account?.servicePlan?.currency || "USD")} ${t("pricing-modal.per-userMonth")}`;
    }

    function handleUserChange(userCount: string) {
        const value = Math.max(1, parseInt(userCount) || 1);
        setNumberOfUsers(value);
    }

    function handleContactClick() {
        setIsContactForm(true);
    }

    function handleBackToPricing() {
        setIsContactForm(false);
    }

    function renderPricingContent() {
        return (
            <div className="pricing-card">
                {/* <div className="pricing-card-number-users">
                    <label>How many users do you have?</label>
                    <input
                        type="number"
                        className="input"
                        value={numberOfUsers}
                        onChange={(e) => handleUserChange(e.target.value)}
                    />
                </div> */}
                <div className="pricing-card-month-year">
                    <div className={`pricing-card-month ${!isYearly ? "active" : ""}`} onClick={() => setIsYearly(false)}>
                        {t("pricing-modal.monthly")}
                    </div>
                    <div className={`pricing-card-year ${isYearly ? "active" : ""}`} onClick={() => setIsYearly(true)}>
                        {t("pricing-modal.yearly")}
                    </div>
                </div>
                <div className='pricing-card-discount'>{t("pricing-modal.discount")}</div>
                <div className="pricing-card-preview-wrapper">
                    {/* Free Plan */}
                    <div className="pricing-card-item">
                        <div>
                            <div className="pricing-card-item-title">{t("pricing-modal.tasterTitle")}</div>
                            <div className="pricing-card-item-subtitle">{t("pricing-modal.tasterSubtitle")}</div>
                        </div>
                        <div>
                            <div className="pricing-card-item-price">{calculatePrice('free')}</div>
                            <div className="pricing-card-item-subtitle-price">{calculateSubtitlePrice('free')}</div>
                        </div>
                        <div className="pricing-card-item-hr"></div>
                        <div className="pricing-card-item-description">
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.tasterUser")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.tasterVideoCalls")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.tasterAppointments")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.tasterCustomers")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.tasterNoCard")}</span>
                        </div>
                        <div className="pricing-card-item-btn" onClick={(e) => portalClick(e, 'free')}>{t("pricing-modal.continueFree")}</div>
                    </div>

                    {/* Standard Plan */}
                    <div className="pricing-card-item popular">
                        <div>
                            <div className="pricing-card-item-title">{t("pricing-modal.standardTitle")}</div>
                            <div className='popular-tag'>{t("pricing-modal.popularTag")}</div>
                            <div className="pricing-card-item-subtitle">{t("pricing-modal.standardSubtitle")}</div>
                        </div>
                        <div>
                            <div className="pricing-card-item-price">{calculatePrice('standard')}</div>
                            <div className="pricing-card-item-subtitle-price">{calculateSubtitlePrice('standard')}</div>
                        </div>
                        <div className="pricing-card-item-hr"></div>
                        <div className="pricing-card-item-description">
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.standardUsers")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.standardVideoCalls")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.standardAppointments")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.standardCustomers")}</span>
                        </div>
                        <div className="pricing-card-item-btn" onClick={(e) => portalClick(e, 'standard')}>{t("pricing-modal.getStarted")}</div>
                    </div>

                    {/* Enterprise Plan */}
                    <div className="pricing-card-item">
                        <div>
                            <div className="pricing-card-item-title">{t("pricing-modal.enterpriseTitle")}</div>
                            <div className="pricing-card-item-subtitle">{t("pricing-modal.enterpriseSubtitle")}</div>
                        </div>
                        <div className="pricing-card-item-price-enterprice">{t("pricing-modal.enterpriseMain")}</div>
                        <div className="pricing-card-item-hr"></div>
                        <div className="pricing-card-item-description">
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.enterpriseUsers")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.enterpriseAppointments")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.enterpriseProducts")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.enterpriseAnalytics")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.enterpriseCustomMinutes")}</span>
                            <span><i className="fas fa-check-circle"></i>{t("pricing-modal.enterpriseFeatures")}</span>
                        </div>
                        <div className="pricing-card-item-btn" onClick={handleContactClick}>{t("pricing-modal.contactUs")}</div>
                    </div>
                </div>
            </div >
        );
    }


    return (
        <Modal size='xl' show={showModal} onHide={handleClose} className='pricing-modal'>
            <Modal.Header closeButton>
                <Modal.Title>{isContactForm ? '' : t("pricing-modal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isContactForm ? <ContactForm onClose={handleBackToPricing} /> : renderPricingContent()}
            </Modal.Body>
        </Modal>
    );

}

type ContactProps = {
    onClose: () => void;
}

function ContactForm(props: ContactProps) {
    const { t } = useTranslation("common");
    const [userEmail, setUserEmail] = useState('');
    const user = useSelector<State, User>(state => state.authentication.user);

    useEffect(() => {
        userService.getById(user.id)
            .then(user => setUserEmail(user.email))
            .catch(error => {
                console.error("Failed to fetch user data:", error);
            });
    }, [user.id]);

    return (
        <div className="contact-form">
            <div className='contact-form-title-wrap'>
                <img className='contact-form-img' src="./Contact_Us_Vector.svg" alt="logo" />
                <div className='contact-form-title'>{t("pricing-modal.contactTitle")}</div>
            </div>
            <div className="form-group">
                <label htmlFor="name">{t("pricing-modal.contactName")}</label>
                <input type="text" className="form-control" id="name" defaultValue={user.name} placeholder="Enter Name" />
            </div>
            <div className="form-group">
                <label htmlFor="email">{t("pricing-modal.contactEmail")}</label>
                <input type="email" className="form-control" id="email" defaultValue={userEmail} placeholder="Enter Email" />
            </div>
            <div className="form-group">
                <label htmlFor="message">{t("pricing-modal.contactMessage")}</label>
                <textarea className="form-control" id="message" rows={3} placeholder="Your message"></textarea>
            </div>
            <div className='contact-form-wrap'>
                <div className='contact-form-back' onClick={props.onClose}>{t("pricing-modal.contactBack")}</div>
                <div className='contact-form-send'>{t("pricing-modal.contactSend")}</div>
            </div>
        </div>
    );
}
